const INIT_TODO_STATE = "INIT_TODO_STATE";
const CLEAR_TODO_STATE = "CLEAR_TODO_STATE";
const ADD_TODO_ITEM = "ADD_TODO_ITEM";
const EDIT_TODO_ITEM = "EDIT_TODO_ITEM";
const REMOVE_TODO_ITEM = "REMOVE_TODO_ITEM";
const TOGGLE_TODO_ITEM = "TOGGLE_TODO_ITEM";
const CLEAR_COMPLETED_TODO_ITEMS = "CLEAR_COMPLETED_TODO_ITEMS";
const CHANGE_ACTIVE_FILTER = "CHANGE_ACTIVE_FILTER";
const TOGGLE_ALL_TODO_ITEMS = "SELECT_ALL_TODO_ITEMS";
const CHANGE_TEXT_OF_TODO_ITEM = "CHANGE_TEXT_OF_TODO_ITEM";

const LOCALSTORAGE_TODO_STATE = "LOCALSTORAGE_TODO_STATE";

export {
  INIT_TODO_STATE,
  CLEAR_TODO_STATE,
  ADD_TODO_ITEM,
  EDIT_TODO_ITEM,
  REMOVE_TODO_ITEM,
  TOGGLE_TODO_ITEM,
  CLEAR_COMPLETED_TODO_ITEMS,
  CHANGE_ACTIVE_FILTER,
  TOGGLE_ALL_TODO_ITEMS,
  CHANGE_TEXT_OF_TODO_ITEM,
  LOCALSTORAGE_TODO_STATE
};